import { useState, useEffect } from 'react';
import VistaprintAuth from '@vp/auth';

export default function useIdentity() {
    const auth = new VistaprintAuth.WebAuth();
    const [identity, setIdentity] = useState(auth.getIdentity());

    useEffect(() => {
        const handleIdentityUpdate = (event: any) => setIdentity(event.detail);
        window.addEventListener('userIdentity', handleIdentityUpdate);
        return () => {
            window.removeEventListener('userIdentity', handleIdentityUpdate);
        }
    });

    return identity;
}
