import VistaprintAuth from '@vp/auth';

export default function init(options: any): Promise<any> {
    return new Promise<any>((resolve: any, reject: any) => {
        VistaprintAuth.init(options, (identity: any) => {
            resolve(identity);
        }, (error: any) => {
            reject(error);
        });
    });
}
