import {default as VpAuth} from '@vp/auth';
import init from './init';
import useIdentity from './hooks/useIdentity';

export interface Auth {
    WebAuth: () => any,
    init: (options: any) => Promise<any>
}

const VistaprintAuth: Auth = {
    WebAuth: VpAuth.WebAuth,
    init: init
};

export { VistaprintAuth, useIdentity };
